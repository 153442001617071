@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap');

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: #4a5568;
    border-radius: 0px;
}

::-webkit-scrollbar-track {
    background-color: #cbd5e0;
}

::-ms-reveal {
    display: none;
}


.MuiDrawer-paper {
    display: unset !important;
}


.spin-button-none::-webkit-outer-spin-button,
.spin-button-none::-webkit-inner-spin-button {
    appearance: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}


@layer components {
    .h1-p {
        @apply font-work-sans font-bold tracking-wide text-slate-800 text-[clamp(22px,3vw,28px)];
    }

    .h2-p {
        @apply font-work-sans font-semibold tracking-wide text-slate-800 text-[clamp(20px,3vw,24px)];
    }

    .h3-p {
        @apply font-work-sans font-semibold tracking-wide text-slate-800 text-[clamp(18px,3vw,20px)];
    }

    .h4-p {
        @apply font-work-sans font-semibold tracking-wide text-slate-800 text-[clamp(16px,3vw,18px)];
    }

    .h5-p {
        @apply font-work-sans font-semibold tracking-wide text-slate-800 text-[14px];
    }

    .lb-p {
        @apply font-[Poppins] font-medium tracking-wide text-slate-800 text-[14px];
    }

    .text-p {
        @apply font-[Poppins] text-slate-950 text-[12px];
    }

    .h1-s {
        @apply font-work-sans font-semibold tracking-wide text-zinc-400 text-[clamp(22px,3vw,28px)];
    }

    .h2-s {
        @apply font-work-sans font-semibold tracking-wide text-zinc-400 text-[clamp(20px,3vw,24px)];
    }

    .h3-s {
        @apply font-[Poppins] font-semibold tracking-wide text-zinc-400 text-[clamp(18px,3vw,20px)];
    }

    .h4-s {
        @apply font-work-sans font-semibold tracking-wide text-zinc-400 text-[clamp(16px,3vw,18px)];
    }

    .h5-s {
        @apply font-work-sans font-semibold tracking-wide text-zinc-400 text-[14px];
    }

    .lb-s {
        @apply font-[Poppins] font-medium text-zinc-400 tracking-wide text-[14px];
    }

    .text-s {
        @apply font-[Poppins] text-zinc-400 text-[12px];
    }

    .nav-bg {
        @apply bg-black bg-gradient-to-br from-[#594D9B]/30 to-transparent;
    }

    .navButtonActive {
        @apply text-[11111D] font-semibold transition-all duration-75 text-white;
    }

    .navButtonInactive {
        @apply text-base font-[Poppins] font-medium text-gray-400;
    }

    .btn-gradient-border {
        @apply text-lg px-8 py-2 rounded-lg transition-all duration-500 ease-in-out;
        color: rgba(256, 256, 256);
        border: 2px double transparent;
        background-image: linear-gradient(rgb(13, 14, 33), rgb(13, 14, 33)),
            radial-gradient(circle at left top, rgb(1, 110, 218), rgb(217, 0, 192));
        background-origin: border-box;
        background-clip: padding-box, border-box;

        &:hover {
            box-shadow: rgba(111, 76, 255, 0.5) 0px 0px 20px 0px;
        }
    }
}